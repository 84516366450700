<template>
  <div class="card-component-container">
    <div class="card" v-if="cardDetails.prohibited">
      <header>{{ cardDetails.word }}</header>
      <ul class="prohibited-words-container">
        <li
          v-for="word in cardDetails.prohibited"
          :key="word"
        >
          {{ word }}
        </li>
      </ul>
    </div>
    <div class="hide-card-container" v-if="!showCard"></div>
  </div>
</template>

<script>
export default {
  props: ["cardDetails", "showCard"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
  },
};
</script>

<style scoped>
.card-component-container {
  position: relative;
  text-align: center;
  width: 70%;
  border: 1px solid black;
  border-radius: 10px;
  max-width: 300px;
  margin: auto;
}
.hide-card-container {
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: purple;
  position: absolute;
  top: 0;
  left: 0;
}
.prohibited-words-container {
  list-style-type: none;
  padding: 0;
}
.card > header {
  text-transform: capitalize;
  font-weight: bold;
  font-weight: bold;
  border-bottom: 1px solid black;
  padding: 30px 0;
  font-size: 25px;
}
.prohibited-words-container > li {
  text-transform: capitalize;
  margin: 25px 0;
  font-size: 20px;
}
</style>