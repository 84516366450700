<template>
  <div class="speech-to-text-modal-container">
    <div class="modal-container">
      <h2>Microphone is live, check the accuracy below.</h2>
      <div class="speech-to-text-output-container">
        <p class="speech-to-text-output">{{ this.speechToTextResults }}</p>
      </div>
      <p class="speech-to-text-confidence-level">
        Confidence:
        {{
          (Math.round(speechToTextResultsConfidence * 10000) / 100).toFixed(2)
        }}%
      </p>
      <br />
      <button @click="close">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "audioStream",
    "speechToTextResults",
    "speechToTextResultsConfidence",
  ],
  data() {
    return {
      sTTRating: 1,
    };
  },
  methods: {
    selectRating(event) {
      this.sTTRating = event.target.value;
    },
    close() {
      this.$emit("close", this.sTTRating);
    },
  },
};
</script>

<style scoped>
.speech-to-text-modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.modal-container {
  padding: 20px;
  position: absolute;
  width: 80%;
  height: 40%;
  max-height: 300px;
  max-width: 500px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.speech-to-text-output-container {
  border: 1px solid grey;
  width: 90%;
  height: 100px;
  margin: auto;
}
.speech-to-text-output {
}
</style>