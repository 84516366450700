<template>
  <div id="home">
    <div v-if="!existingGame" id="game-setup-form">
      <form>
        Round Duration (seconds):
        <input
          v-model="gameDuration"
          type="number"
          id="game-duration"
        />s<br /><br />
        Number of Categories:
        <input
          v-model="numberCategories"
          type="number"
          id="number-categories"
        /><br /><br />
        <button class="home-page-button" @click="submitGameSetup">
          Create Game
        </button>
      </form>
      <br /><br />
    </div>
    <button
      class="home-page-button"
      v-if="existingGame"
      @click="redirectToGame"
    >
      Join Existing Game
    </button>
    <button class="home-page-button" @click="redirectToCategoriesPage">
      View/Modify Categories
    </button>
  </div>
</template>

<script>
let socketConstants = require("../../constants/scattergories/socket-constants");
export default {
  name: "Home",
  data() {
    return {
      gameDuration: 90,
      numberCategories: 10,
      existingGame: false,
    };
  },
  mounted() {
    this.gameDuration = this.$store.state.gameDuration;
    this.numberCategories = this.$store.state.numberCategories;

    fetch(this.$store.state.serverAddress + "/game")
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.existingGame = !!res.gameDuration;
      });

    this.sockets.subscribe(socketConstants.setupGame, () => {
      console.log("Game has been setup");
      this.existingGame = true;
    });
    this.sockets.subscribe(socketConstants.endGame, () => {
      this.existingGame = false;
    });
  },

  beforeDestroy() {
    this.sockets.unsubscribe(socketConstants.setupGame);
    this.sockets.unsubscribe(socketConstants.endGame);
    this.sockets.unsubscribe(socketConstants.startGame);
  },
  methods: {
    submitGameSetup() {
      let gameDuration = this.gameDuration;
      let numberCategories = this.numberCategories;
      console.log("Submitting game");
      console.log(socketConstants.setupGame);
      this.$socket.emit(socketConstants.setupGame, {
        gameDuration: gameDuration,
        numberCategories: numberCategories,
      });
      this.redirectToGame();
    },
    redirectToGame() {
      this.$router.push({
        path: "play",
        params: { gameStarted: this.existingGame },
      });
    },
    redirectToCategoriesPage() {
      this.$router.push("categories");
    },
  },
};
</script>

<style scoped>
input {
  width: 50px;
}
.home-page-button {
  padding: 25px;
  font-size: 25px;
  background-color: lightblue;
  font-weight: bold;
}
</style>