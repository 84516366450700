<template>
  <div id="play">
    <div id="timer">
      <audio id="time-over-audio" hidden>
        <source
          src="../../assets/scattergories/alarm_tone.mp3"
          type="audio/mp3 "
        />
      </audio>
      <span id="minute-countdown"></span> Minute(s)
      <span id="second-countdown"></span> Second(s)
    </div>
    <div id="game">
      <div id="letter-container">Letter: <span id="letter"></span></div>
      <ol id="game-categories" v-if="gameDetails">
        <li
          class="game-category"
          v-for="category in gameDetails.categories"
          :key="category"
        >
          {{ category }}
        </li>
      </ol>
      <button id="end-game-button" @click="endGame">End Game</button>
    </div>
    <button id="start-game-button" @click="startGame">Start game</button>
  </div>
</template>

<script>
let socketConstants = require("../../constants/scattergories/socket-constants");
export default {
  name: "Play",
  props: ["gameStarted"],
  data() {
    return {
      gameDetails: this.gameDetails,
      refreshIds: [],
    };
  },
  mounted() {
    if (this.$route.params.gameStarted) {
      this.startGame(null, false);
    }
    this.sockets.subscribe(socketConstants.startGame, (data) => {
      this.gameDetails = data;
      console.log("Starting game");
      console.log(data);
      this.gameSetup();
    });
    this.sockets.subscribe(socketConstants.endGame, (goHome) => {
      console.log("Resetting game");
      if (goHome) {
        this.$router.push("home");
      }
    });
    fetch(this.$store.state.serverAddress + "/joined-game", { method: "POST" });
  },
  beforeDestroy() {
    console.log("Destroying");
    fetch(this.$store.state.serverAddress + "/left-game", { method: "POST" });
    this.clearIntervals();
    this.sockets.unsubscribe(socketConstants.startGame);
    this.sockets.unsubscribe(socketConstants.endGame);
  },
  methods: {
    clearIntervals() {
      // Set as array in case of multiple messages received from socket to start
      // This is just a workaround, not ideal way
      this.refreshIds.forEach((id) => {
        clearInterval(id);
      });
    },
    /**
     * e is to handle button click and prevent submission
     * startGameForEveryone is a boolean to determine whether to send everyone
     *  to "/play", or just current user
     *  Do not start for everyone if game is already in session and a single user
     *  decides to leave for Home page, etc.
     */
    startGame(e, startGameForEveryone) {
      if (e) {
        e.preventDefault();
      }
      console.log("Starting game for everyone");
      console.log(startGameForEveryone);
      if (startGameForEveryone !== false) {
        this.$socket.emit(socketConstants.startGame);
      }
    },
    startTimer() {
      var timeRemaining = this.getRemainingTimeInSeconds();
      var minutesRemaining = Math.floor(timeRemaining / 60);
      var secondsRemaining = timeRemaining % 60;
      let refreshId = setInterval(() => {
        secondsRemaining--;
        if (minutesRemaining === 0 && secondsRemaining === 0) {
          this.clearIntervals();
          let timeOverAudio = document.getElementById("time-over-audio");
          timeOverAudio.play();
          setTimeout(() => {
            timeOverAudio.pause();
          }, 3000);
          this.endGame(false);
        }
        if (secondsRemaining === -1) {
          secondsRemaining = 59;
          if (minutesRemaining > 0) {
            minutesRemaining--;
          }
        }
        document.getElementById("minute-countdown").innerHTML =
          minutesRemaining + "";
        document.getElementById("second-countdown").innerHTML =
          secondsRemaining + "";
      }, 1000);
      this.refreshIds.push(refreshId);
    },
    // goHome determines whether to send all users to home page or not
    //  true if game is ended via button click
    //  false if timer runs out, to keep categories list open
    endGame(goHome) {
      this.$socket.emit(socketConstants.endGame, goHome);
      this.clearIntervals();
    },
    getRemainingTimeInSeconds() {
      var timeElapsedSinceGameStartInSeconds = Math.round(
        (new Date().getTime() - this.gameDetails.startTime) / 1000
      );
      return this.gameDetails.gameDuration - timeElapsedSinceGameStartInSeconds;
    },
    gameSetup() {
      this.$store.commit("setGameDuration", this.gameDetails.gameDuration);
      this.$store.commit(
        "setNumberCategories",
        this.gameDetails.numberCategories
      );
      console.log("In game setup");
      console.log(this.gameDetails);
      var timeRemaining = this.getRemainingTimeInSeconds();
      var minutesRemaining = Math.floor(timeRemaining / 60);
      var secondsRemaining = timeRemaining % 60;
      document.getElementById("minute-countdown").innerHTML = minutesRemaining;
      document.getElementById("second-countdown").innerHTML = secondsRemaining;
      document.getElementById("letter").innerHTML = this.gameDetails.letter;
      document.getElementById("game").style.display = "block";
      document.getElementById("start-game-button").style.display = "none";
      this.startTimer();
    },
  },
};
</script>

<style scoped>
#timer {
  font-size: 50px;
  text-align: center;
}

#game-categories {
  width: fit-content;
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 50px;
  padding: 0;
  list-style-position: inside;
}

.game-category {
  padding: 20px 40px;
  font-size: 20px;
}

.game-category:nth-child(even) {
  background-color: #ebebeb;
}

.game-category:nth-child(odd) {
  background-color: #d6d6d6;
}

#game {
  display: none;
}

#letter-container {
  font-size: 50px;
  text-align: center;
  margin-top: 50px;
}

#end-game-button {
  margin-top: 100px;
}

#start-game-button {
  margin-top: 40vh;
}

#start-game-button,
#end-game-button {
  font-size: 50px;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #21ad4b;
  cursor: pointer;
}
</style>
