<template>
  <div id="home">
    <div id="lobby">
      <p id="name-string" v-if="playerName">
        Your Name: <span id="name">{{ playerName }}</span>
      </p>
      <div id="join-game-form" v-if="!playerName">
        <p id="error-message">{{ errorMessage }}</p>
        <input
          type="text"
          v-model="typedName"
          id="name-field"
          placeholder="Enter name"
          autocomplete="off"
        /><br />
        <button
          id="join-game-button"
          @click="joinGame"
          :disabled="typedName.trim().length === 0"
        >
          Join Game
        </button>
      </div>
      <div id="lobby">
        <p>Lobby:</p>
        <ul id="players">
          <li
            class="player"
            v-for="player in players"
            :key="player"
            :data-player="player"
            @click="selectMissionLeader"
          >
            {{ player }}
            <span
              class="remove-player-button"
              @click="removePlayer"
              :data-player="player"
            >
              X
            </span>
          </li>
        </ul>
      </div>
    </div>
    <br /><br />
    <input type="checkbox" v-model="enableMerlin" />Merlin<br /><br />
    <button
      @click="startGame"
      :disabled="
        players.length < 5 || players.length > 10 || !firstMissionLeader
      "
    >
      Start Game
    </button>
  </div>
</template>

<script>
import SocketConstants from "../../constants/resistance/socket-constants";
import ErrorMessages from "../../constants/resistance/error-messages";
export default {
  name: "Home",
  data() {
    return {
      inGame: false,
      players: [],
      lowercasePlayers: [],
      typedName: "",
      errorMessage: "",
      playerName: null,
      firstMissionLeader: null,
      firstMissionLeaderElement: null,
      enableMerlin: false,
    };
  },
  mounted() {
    fetch(this.$store.state.serverAddress + "/resistance/players")
      .then((r) => {
        return r.json();
      })
      .then((res) => {
        res.forEach((n) => {
          this.players.push(n);
          this.lowercasePlayers.push(n.toLowerCase());
        });
        // Automatically come up with names and join games for easier and faster testing
        setTimeout(() => {
          let tempName = "";
          tempName += String.fromCharCode(
            Math.round(Math.random() * 25) + "A".charCodeAt(0)
          );
          tempName += String.fromCharCode(
            Math.round(Math.random() * 25) + "A".charCodeAt(0)
          );
          tempName += String.fromCharCode(
            Math.round(Math.random() * 25) + "A".charCodeAt(0)
          );
          tempName += String.fromCharCode(
            Math.round(Math.random() * 25) + "A".charCodeAt(0)
          );
          tempName += String.fromCharCode(
            Math.round(Math.random() * 25) + "A".charCodeAt(0)
          );

          this.typedName = tempName;
          this.joinGame();
        }, 1000);
      });

    this.sockets.subscribe(SocketConstants.playerJoined, (name) => {
      this.players.push(name);
      this.lowercasePlayers.push(name.toLowerCase());
    });
    this.sockets.subscribe(SocketConstants.removePlayer, (name) => {
      let indexOfPlayer = this.players.indexOf(name);
      this.players.splice(indexOfPlayer, 1);
      this.lowercasePlayers.splice(indexOfPlayer, 1);
      if (name === this.playerName) {
        this.playerName = null;
      }
      if (name === this.firstMissionLeader) {
        this.firstMissionLeader === null;
        this.firstMissionLeaderElement === null;
      }
    });

    this.sockets.subscribe(SocketConstants.startGame, (data) => {
      this.$router.push({
        name: "Resistance_Play",
        params: { gameSetup: data, playerName: this.playerName },
      });
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe(SocketConstants.playerJoined);
    this.sockets.unsubscribe(SocketConstants.removePlayer);
    this.sockets.unsubscribe(SocketConstants.startGame);
  },

  methods: {
    joinGame() {
      console.log("Join game has been clicked");
      if (
        this.lowercasePlayers.indexOf(this.typedName.trim().toLowerCase()) ===
        -1
      ) {
        console.log("Inside of if");
        this.playerName = this.typedName.trim();
        console.log("We are joining the game with name: " + this.typedName);
        this.$socket.emit(SocketConstants.playerJoined, this.typedName.trim());
      } else {
        console.log("Inside of else");
        this.errorMessage = ErrorMessages.playerExists;
      }
      this.typedName = "";
    },
    removePlayer(e, name) {
      let playerName;
      if (e) {
        playerName = e.target.getAttribute("data-player");
      } else {
        playerName = name;
      }
      this.$socket.emit(SocketConstants.removePlayer, playerName);
    },
    startGame() {
      this.$socket.emit(SocketConstants.startGame, {
        firstMissionLeader: this.firstMissionLeader,
        enableMerlin: this.enableMerlin,
      });
    },

    selectMissionLeader(e) {
      if (this.firstMissionLeaderElement) {
        this.firstMissionLeaderElement.classList.remove("selected");
      }
      this.firstMissionLeaderElement = e.target;
      this.firstMissionLeader = e.target.getAttribute("data-player");
      e.target.classList.add("selected");
    },
  },
};
</script>

<style scoped>
#name-string {
  font-size: 20px;
}
#name {
  font-weight: bold;
}
#home {
  text-align: center;
}
#name-field {
  max-width: 500px;
  width: 90%;
  font-size: 25px;
  text-align: center;
  margin: auto;
}

#join-game-button {
  margin: auto;
  margin-top: 20px;
}
#lobby {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

#players {
  padding: 0;
  list-style-position: inside;
  text-align: left;
  list-style-type: none;
}

.player {
  font-size: 30px;
  padding: 20px;
  padding-left: 50px;
  position: relative;
  margin: 20px auto;
}

.player:nth-child(even) {
  background-color: pink;
}
.player:nth-child(odd) {
  background-color: lightblue;
}

.player:hover,
.player.selected {
  box-shadow: 0px 0px 50px black;
  z-index: 3;
  font-weight: bold;
  transform: translateX(-10px);
}

.remove-player-button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.remove-player-button:hover {
  cursor: pointer;
  color: red;
}

#error-message {
  font-size: 26px;
  color: red;
}
</style>