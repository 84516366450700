<template>
  <div id="scattergories">
    <h1>Scattergories</h1>
    <router-view></router-view>
  </div>
</template>

<script>
let socketConstants = require("../constants/scattergories/socket-constants");
// import Home from "../components/scattergories/Home";
export default {
  name: "Scattergories",
  //   components: { Home },
  data() {
    return {
      gameDetails: {},
    };
  },
  mounted() {
    // If game has been created by any device, send all users from any page to /play
    this.sockets.subscribe(socketConstants.setupGame, () => {
      console.log("Game has been setup");
      this.existingGame = true;

      if (this.$route.name !== "Play") {
        this.$router.push("play");
      }
    });

    // If game is started by any device, send all users from any page to /play
    this.sockets.subscribe(socketConstants.startGame, () => {
      console.log(this.$route.name);
      if (this.$route.name !== "Play") {
        this.$router.push({ name: "Scattergories_Play", params: { gameStarted: true } });
      }
    });
  },
  beforeDestroy() {
    // Unsubscribe from all sockets when this component is closed
    this.sockets.unsubscribe(socketConstants.startGame);
    this.sockets.unsubscribe(socketConstants.setupGame);
  },
};
</script>

<style scoped>
</style>
