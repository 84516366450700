const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition
let speechRecognitionSupportedByDevice = false;
let mic;

if (SpeechRecognition) {
    speechRecognitionSupportedByDevice = true;
    mic = new SpeechRecognition()
    mic.continuous = false
    mic.interimResults = true
    mic.lang = 'en-US'
}

let listening = false;

function startAudioRecording(callback) {
    listening = true;
    if (listening) {
        mic.start();
        mic.onresult = event => {
            let confidence = 0;
            Array.from(event.results)
                .map(result => result[0])
                .forEach((result) => {
                    confidence += result.transcript.split(" ").length * result.confidence;
                })
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('')
            confidence /= transcript.split(" ").length;
            callback(transcript, confidence);
            mic.onerror = event => {
                console.log(event.error)
            }
        }
        mic.onend = () => {
            mic.start()
        }
    }
}

function stopAudioRecording() {
    listening = false;
    mic.stop();
    mic.onend = () => { }
}

module.exports = { startAudioRecording, stopAudioRecording, speechRecognitionSupportedByDevice }
