<template>
  <div id="play">
    <div class="score-container">
      <p class="timer">{{ timeRemaining }}s</p>
      <p>Team 1 Score: {{ team1Score }}</p>
      <p>Team 2 Score: {{ team2Score }}</p>
    </div>
    <div class="game-container" v-if="!roundOver">
      <div class="card-container">
        <Card :cardDetails="cardDetails" :showCard="showCard" />
      </div>
      <div class="buttons-container">
        <button v-if="!showCard" @click="start">Start</button>
        <button v-if="showCard" class="wrong-button wrong" @click="wrong">
          Wrong
        </button>
        <button
          v-if="showCard"
          class="skipped-button skipped"
          @click="skipCard"
        >
          Skip
        </button>
        <button v-if="showCard" class="correct-button correct" @click="correct">
          Correct
        </button>
      </div>
    </div>
    <div class="end-of-round-container" v-if="roundOver">
      <p class="round-score">
        Round Score: <span class="round-score-value">{{ roundScore }}</span>
      </p>
      <Card
        v-for="card in completedCards.filter((card) => card.status !== '')"
        :class="card.status"
        :key="card.cardIndex"
        :cardDetails="words[card.cardIndex]"
        :showCard="true"
      />
      <div class="buttons-container">
        <button @click="nextRound">Next Round</button>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../../components/taboo/Card";
import words from "../../constants/taboo/words";
import SpeechToTextService from "../../services/taboo/SpeechToTextService";

export default {
  components: { Card },
  data() {
    return {
      timeRemaining: 0,
      cardDetails: {},
      showCard: false,
      displayedCards: new Set(),
      completedCards: [],
      roundOver: false,
      team1Score: 0,
      team2Score: 0,
      words: words,
      roundScore: 0,
      numRound: 0,
    };
  },
  mounted() {
    this.configureRound();
  },
  beforeDestroy() {
    SpeechToTextService.stopAudioRecording();
  },
  methods: {
    configureRound() {
      this.numRound++;
      this.roundOver = false;
      this.showCard = false;
      this.timeRemaining = this.$route.params.gameDuration;
      this.completedCards = [];
      this.newCard();
    },
    start() {
      this.showCard = true;
      if (this.$route.params.enableSpeechRecognition) {
        SpeechToTextService.startAudioRecording(this.verifyHints);
      }
      let x = setInterval(() => {
        this.timeRemaining--;
        if (this.timeRemaining === 0) {
          clearInterval(x);
          this.endRound();
        }
      }, 1000);
    },
    newCard() {
      let rand = Math.round(Math.random() * words.length);
      while (this.displayedCards.has(rand)) {
        rand = Math.round(Math.random() * words.length);
      }
      this.cardDetails = words[rand];
      this.cardDetails.id++;
      this.displayedCards.add(rand);
      let randomizedProhibitedList = this.randomizeList(
        this.cardDetails.prohibited
      ).slice(0, 4);
      this.cardDetails.prohibited = randomizedProhibitedList;
      this.completedCards.push({ cardIndex: rand, status: "" });
    },
    markCard(status) {
      this.completedCards[this.completedCards.length - 1].status = status;
    },
    skipCard() {
      this.numSkipped++;
      this.markCard("skipped");
      this.newCard();
    },
    correct() {
      this.numCorrect++;
      this.markCard("correct");
      this.newCard();
    },
    wrong() {
      this.numWrong++;
      this.markCard("wrong");
      this.newCard();
    },
    endRound() {
      SpeechToTextService.stopAudioRecording();
      this.roundOver = true;
      let numSkipped = 0;
      let numWrong = 0;
      let numCorrect = 0;
      this.completedCards.forEach((card) => {
        if (card.status === "skipped") {
          numSkipped++;
        } else if (card.status === "wrong") {
          numWrong++;
        } else if (card.status === "correct") {
          numCorrect++;
        }
        let skippedScore =
          numSkipped > this.$route.params.skipsAllowed
            ? numSkipped - this.$route.params.skipsAllowed
            : 0;
        this.roundScore = numCorrect - skippedScore - numWrong;
      });
      if (this.numRound % 2 === 1) {
        this.team1Score += this.roundScore;
      } else {
        this.team2Score += this.roundScore;
      }
    },
    nextRound() {
      this.configureRound();
    },
    verifyHints(results, confidence) {
      if (confidence > 0.8) {
        let splitResults = results.split(" ");
        for (let i = 0; i < splitResults.length; i++) {
          let word = splitResults[i];
          if (this.isSignificantPartOfWord(word, this.cardDetails.word)) {
            this.wrong();
            return;
          }
          for (let j = 0; j < this.cardDetails.prohibited.length; j++) {
            let prohibWord = this.cardDetails.prohibited[j];
            if (this.isSignificantPartOfWord(word, prohibWord)) {
              this.wrong();
              return;
            }
          }
        }
      }
    },
    isSignificantPartOfWord(result, cardWord) {
      // if the word picked up from the speech to text result has a length > 3, or if the length of the result word is greater than half of the length of the cardWord, we are treating the STT word as being a significant part of cardWord, if cardWord includes result
      return (
        (result.length > 3 || result.length > cardWord.length / 2) &&
        cardWord.toLowerCase().includes(result.toLowerCase())
      );
    },
    randomizeList(list) {
      return list.reduce(
        (a, v) => a.splice(Math.floor(Math.random() * list.length), 0, v) && a,
        []
      );
    },
  },
};
</script>

<style scoped>
.score-container {
  width: 40%;
  margin: auto;
  text-align: center;
  border: 1px solid black;
  margin-bottom: 100px;
}
.timer {
  font-weight: bolder;
  font-size: 30px;
}
.buttons-container {
  margin: 50px auto;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.buttons-container > button {
  padding: 10px 20px;
  font-size: 15px;
}
.wrong-button,
.skip-button,
.correct-button {
  font-weight: bold;
  border: 1.5px solid black;
}
.wrong {
  background-color: rgba(255, 0, 0, 0.801);
}
.skipped {
  background-color: yellow;
}
.correct {
  background-color: rgb(19, 255, 19);
}
.card-component-container + .card-component-container {
  margin-top: 50px;
}
.round-score {
  text-align: center;
}
.round-score-value {
  font-weight: bold;
}
</style>