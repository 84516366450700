<template>
  <div id="resistance">
    <h1>Resistance</h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Resistance",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#resistance {
  text-align: center;
}
</style>