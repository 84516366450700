import Vue from 'vue'
import App from './App.vue'
import Scattergories from "./game_entry/Scattergories";
import ScattergoriesHome from "./components/scattergories/Home";
import ScattergoriesPlay from "./components/scattergories/Play";
import ScattergoriesCategories from "./components/scattergories/Categories";
import Resistance from "./game_entry/Resistance";
import ResistanceHome from "./components/resistance/Home";
import ResistancePlay from "./components/resistance/Play";
import Taboo from './game_entry/Taboo';
import TabooHome from './components/taboo/Home';
import TabooPlay from './components/taboo/Play';
import VueRouter from "vue-router";
import VueSocketIO from 'vue-socket.io';
import Vuex from 'vuex';

// for personal machine development
// const serverAddress = "http://localhost:3001"

// for deployment
const serverAddress = "https://hashmi.site:3001"

let randomUserId = "";
for (let i = 0; i < 15; i++) {
  randomUserId += String.fromCharCode(Math.round(Math.random() * 25) + 'A'.charCodeAt(0));
}


const vueSocketConnetion = new VueSocketIO({
  debug: true,
  connection: serverAddress
});

Vue.use(VueRouter);
Vue.use(vueSocketConnetion);
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    randomUserId: randomUserId,
    gameDuration: 90,
    numberCategories: 10,
    serverAddress
  },
  mutations: {
    setGameDuration(state, val) {
      state.gameDuration = val;
    },
    setNumberCategories(state, val) {
      state.numberCategories = val;
    }
  }
})

const homeRoute = {
  path: "/home", name: "Home Page"
}

const scattergoriesRoutes = {
  path: "/scattergories", redirect: "/scattergories/home", name: "Scattergories", component: Scattergories, children: [
    { path: "home", name: "Scattergories_Home", component: ScattergoriesHome },
    { path: "play", name: "Scattergories_Play", component: ScattergoriesPlay },
    { path: "categories", name: "Scattergories_Categories", component: ScattergoriesCategories }
  ],
  locked: true
}


const resistanceRoutes = {
  path: "/resistance", redirect: "/resistance/home", name: "Resistance", component: Resistance, children: [
    { path: "/resistance/home", name: "Resistance_Home", component: ResistanceHome },
    { path: "/resistance/play", name: "Resistance_Play", component: ResistancePlay },
  ]
}

const tabooRoutes = {
  path: "/taboo", redirect: "/taboo/home", name: "Taboo", component: Taboo, children: [
    { path: "/taboo/home", name: "Taboo_Home", component: TabooHome },
    { path: "/taboo/play", name: "Taboo_Play", component: TabooPlay },],
  locked: true
}

let routes = [
  homeRoute,
  scattergoriesRoutes,
  resistanceRoutes,
  tabooRoutes
];


Vue.config.productionTip = false;
const router = new VueRouter({ mode: 'history', routes });

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')
