<template>
  <div id="categories">
    <h1>Categories</h1>
    <form>
      <input id="add-category-input" type="text" placeholder="Add category" />
      <button @click="addCategory">Submit</button>
    </form>
    <div id="categories-list" v-if="categories">
      <div class="category" v-for="category in categories" :key="category">
        {{ category }}
        <div
          class="delete-category-button"
          :data-category="category"
          @click="removeCategory"
        >
          X
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import socketConstants from "../../constants/scattergories/socket-constants";
export default {
  name: "Categories",
  data() {
    return {
      categoriesList: [],
      categories: [],
    };
  },
  mounted() {
    this.sockets.subscribe(socketConstants.modifyCategories, () => {
      this.addCategoriesListItems();
    });
    this.addCategoriesListItems();
  },
  beforeDestroy() {
    this.sockets.unsubscribe(socketConstants.modifyCategories);
  },
  methods: {
    addCategoriesListItems() {
      fetch(this.$store.state.serverAddress + "/all-categories")
        .then((res) => {
          return res.json();
        })
        .then((categories) => {
          this.categories = categories;
        });
    },

    modifyCategories(category, add) {
      this.$socket.emit(socketConstants.modifyCategories, { category, add });
    },

    addCategory(e) {
      e.preventDefault();
      let input = document.getElementById("add-category-input");
      input.value = input.value.trim();
      if (input.value) {
        this.modifyCategories(input.value, true);
      }
    },

    removeCategory(e) {
      let category = e.target.getAttribute("data-category");
      this.modifyCategories(category, false);
    },
  },
};
</script>

<style scoped>
#categories-list {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.category {
  padding: 10px;
  border: 1px solid black;
  width: 250px;
  position: relative;
  font-size: 20px;
  margin: 5px;
  padding-right: 40px;
}

.delete-category-button {
  position: absolute;
  right: 10px;
  top: 25%;
  cursor: pointer;
}

.delete-category-button:hover {
  color: red;
  font-size: 125%;
  transform: translateX(10%) translateY(-10%);
}

#add-category-input {
  width: 200px;
  height: 30px;
  font-size: 20px;
  margin-bottom: 50px;
}
</style>