module.exports = {
    startGame: "resistance - start game",
    playerJoined: "resistance - joined",
    removePlayer: "resistance - player left",
    missionLeaderSelections: "resistance - mission leader selections",
    missionResults: "resistance - mission results",
    nextRound: "resistance - next round",
    endGame: "resistance - end game",
    vetoMission: "resistance - veto mission",
    reconnectToGame: "resistance - reconnect to game",
}