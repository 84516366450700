<template>
  <div id="lock-screen">
    <h1>Enter password to unlock {{ gameName }}</h1>
    <input
      v-model="enteredPassword"
      placeholder="Enter password"
      :type="passwordInputType"
      id="password-input"
    />
    <input type="checkbox" @change="() => toggleDisplayPassword()" /> Show
    Password
    <br />
    <br />
    <button @click="() => requestUnlock()">Submit</button>
  </div>
</template>

<script>
export default {
  props: {
    gameName: String,
    updateLockStatus: Function,
  },
  data() {
    return {
      enteredPassword: "",
      passwordInputType: "password",
    };
  },
  methods: {
    requestUnlock() {
      let requestBody = {
        game: this.gameName,
        enteredPassword: this.enteredPassword,
      };
      fetch(this.$store.state.serverAddress + "/unlock-game", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          this.updateLockStatus(res.isLocked);
        });
    },
    toggleDisplayPassword() {
      this.passwordInputType =
        this.passwordInputType === "password" ? "text" : "password";
    },
  },
};
</script>

<style scoped>
</style>