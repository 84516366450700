<template>
  <div id="app">
    <nav>
      <router-link
        v-for="route in $router.options.routes"
        :to="route.path"
        :key="route.name + 'Path'"
        >{{ route.name }}</router-link
      >
    </nav>
    <!-- v-show is not ideal, but v-if prevents page from re-rendering on route change -->
    <router-view v-show="!locked"></router-view>
    <lock-screen
      v-if="locked"
      :gameName="currentRoute.name"
      :updateLockStatus="updateLockStatus"
    />
  </div>
</template>

<script>
import LockScreen from "./components/shared/LockScreen.vue";
export default {
  components: { LockScreen },
  name: "App",
  data() {
    return {
      // Is game locked
      // Needed for virtual versions of purchased games like Taboo and Scattegories
      locked: true,
      currentRoute: {},
    };
  },
  mounted() {
    this.onPageLoad();
    this.sockets.subscribe("connection", () => {
      // Used in order to send a randomly generated user id to server
      // to store
      // This is so that when iOS devices lock screen and socket is
      // disconnected the device can reconnect
      this.$socket.emit("userId", this.$store.state.randomUserId);
    });
  },
  updated() {
    this.onPageLoad();
  },
  methods: {
    // To be triggered by mounted and updated lifecycle methods
    // This is for any common logic that needs to be performed
    onPageLoad() {
      this.getCurrentRoute();
      if (this.currentRoute.locked) {
        // Check API if route is locked if currentRoute should be
        this.isLocked();
      } else {
        // If current route is not supposed to be locked, set this.locked to false
        this.locked = false;
      }
    },
    /**
     * Check if game is locked
     */
    isLocked() {
      fetch(
        this.$store.state.serverAddress +
          "/unlock-game?game=" +
          this.currentRoute.name
      )
        .then((r) => r.json())
        .then((res) => {
          this.locked = res.isLocked;
        });
    },
    /**
     * Get route object corresponding to parent route
     */
    getCurrentRoute() {
      // Find out which app is open
      let currentRoute = this.$router.currentRoute;
      for (let route of this.$router.options.routes) {
        if (currentRoute.name && currentRoute.name.includes(route.name)) {
          this.currentRoute = route;
          return;
        }
      }
    },
    /**
     * Update this.isLocked when unlockGame is attempted on LockScreen component
     * @param {boolean} lockStatus new value to update
     */
    updateLockStatus(lockStatus) {
      this.locked = lockStatus;
    },
  },
  beforeDestroy() {
    this.sockets.unsubscribe("connection", () => {});
  },
};
</script>

<style>
h1 {
  font-size: 300%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}
nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
</style>