<template>
  <div id="play">
    <div id="game-info-container">
      <div id="stats">
        <p>
          <span>Round: </span><span>{{ round + 1 }}</span>
        </p>
        <p>
          <span>Mission Passes: </span>
          <span>{{ missionPasses }}</span>
        </p>
        <p>
          <span>Mission Failures: </span>
          <span>{{ missionFailures }}</span>
        </p>
      </div>
      <div id="roundInformation">
        <p>
          Number of failures required to fail mission:
          {{ failuresNeededPerRound[round] }}
        </p>
        <p>
          Number of members going on mission:
          {{ playersOnMissionPerRound[round] }}
        </p>
      </div>
      <div id="role-container-wrapper">
        <div id="role-container" v-if="role && showRole">
          <span>You are </span><br />
          <span id="role" :class="role.toLowerCase()">{{ role }}</span>
          <div
            id="spies-list-container"
            v-if="
              role.toLowerCase() === 'spy' || role.toLowerCase() === 'merlin'
            "
          >
            <p>Spies:</p>
            <ul id="spies-list">
              <li v-for="spy in spies" :key="spy">{{ spy }}</li>
            </ul>
          </div>
        </div>
        <button @click="toggleDisplayRole">Show/Hide Role</button>
      </div>
    </div>
    <div id="game-container" v-if="!gameOver">
      <div v-if="missionLeader && missionLeader !== playerName">
        <span>Mission Leader is: </span> <b>{{ missionLeader }}</b>
      </div>
      <div v-if="missionLeader && missionLeader === playerName">
        <b>You are the mission leader</b>
      </div>
      <div
        id="mission-leader-options"
        v-if="missionLeader === playerName && !submittedMissionLeaderSelections"
      >
        <p>
          Who would you like to go on this mission? Choose ({{
            playersOnMissionPerRound[round]
          }})
        </p>
        <b
          >***NOTE: If mission has been vetoed (no screen to veto, veto portion
          will happen verbally, select next mission leader and "vetoed".
          "Vetoed" button can only be selected if 0 team members are
          selected***</b
        >
        <ul id="players-for-mission">
          <li
            class="selectable"
            v-for="player in players"
            :key="`${player}Player`"
            :data-player="player"
            @click="(e) => selectPlayer(e, 'missionMember')"
          >
            {{ player }}
          </li>
        </ul>

        <p id="choose-next-mission-leader-label">Choose Next Mission Leader:</p>
        <ul id="players-for-ml">
          <li
            class="selectable"
            v-for="player in eligibleToBeMissionLeaders"
            :key="`${player}ML`"
            :data-player="player"
            @click="(e) => selectPlayer(e, 'missionLeader')"
          >
            {{ player }}
          </li>
        </ul>
        <div
          v-if="
            missionLeader === playerName && !submittedMissionLeaderSelections
          "
          id="mission-leader-buttons-container"
        >
          <button
            id="submit-mission-leader-selection-button"
            :disabled="
              playersGoingOnMission.length !==
                playersOnMissionPerRound[round] || !nextMissionLeader
            "
            @click="submitMissionLeaderSelections"
          >
            Submit Selections
          </button>
          <br />
          <br />
          <br />
          <button
            id="veto-mission-leader-selection-button"
            :disabled="playersGoingOnMission.length > 0 || !nextMissionLeader"
            @click="handleVeto"
          >
            Vetoed
          </button>
        </div>
      </div>
      <div id="mission-results-container" v-if="missionResults.length > 0">
        <div class="card-container">
          <div
            v-for="(card, i) in missionResults"
            :key="'missionResult' + i"
            :class="['mission-result', 'card', card]"
            :style="{ animationDelay: 1.5 * i + 's' }"
          ></div>
        </div>
        <button
          id="next-round-button"
          @click="emitNextRound"
          v-if="
            missionResults.length > 0 &&
            !(missionPasses == 3 || missionFailures == 3)
          "
        >
          Next Round >>>
        </button>
      </div>
      <div id="select-card-container" v-if="onMission && !cardSubmitted">
        <div class="card-container">
          <div
            class="card black"
            @click="selectMissionCard"
            data-color="black"
          ></div>
          <div
            class="card red"
            @click="selectMissionCard"
            data-color="red"
          ></div>
        </div>
        <button
          id="submit-mission-members-selection-button"
          v-if="onMission"
          :disabled="!selectedColor"
          @click="submitMissionMemberCard"
        >
          Submit Card
        </button>
      </div>
    </div>
    <div id="game-over-container" v-if="gameOver">
      <div
        id="winner-container"
        v-if="missionFailures === 3 || missionPasses === 3"
      >
        <span
          v-if="missionFailures === 3"
          class="winning-group"
          id="spies-won-text"
          >SPIES</span
        >
        <span
          v-if="missionPasses === 3"
          class="winning-group"
          id="resistance-won-text"
          >RESISTANCE</span
        >
        <span> WON!!!</span>
      </div>
      <div>Spies:</div>
      <ul id="spy-reveal-container">
        <li class="spy-reveal" v-for="spy in spies" :key="spy">{{ spy }}</li>
      </ul>
    </div>
    <button id="end-game-button" @click="endGame">End Game</button>
    <p v-if="someData">
      Device has reconnected<br />{{ JSON.stringify(someData) }}
    </p>
  </div>
</template>

<script>
import SocketConstants from "../../constants/resistance/socket-constants";
export default {
  name: "Play",
  data() {
    return {
      role: null,
      showRole: true,
      spies: null,
      round: 0,
      missionPasses: 0,
      missionFailures: 0,
      playerName: null,
      playersOnMissionPerRound: [],
      failuresNeededPerRound: [],
      numSpies: 0,
      missionLeader: null,
      players: [],
      eligibleToBeMissionLeaders: [],
      nextMissionLeader: null,
      nextMissionLeaderElement: null,
      playersGoingOnMission: [],
      playersGoingOnMissionElements: [],
      onMission: false,
      selectedColor: null,
      selectedColorElement: null,
      missionResults: [],
      cardSubmitted: false,
      submittedMissionLeaderSelections: false,
      gameOver: false,
      someData: null,
    };
  },
  created() {
    let gameSetup = this.$route.params.gameSetup;
    this.spies = gameSetup.spies;
    this.playersOnMissionPerRound = gameSetup.playersOnMissionPerRound;
    this.failuresNeededPerRound = gameSetup.failuresNeededPerRound;
    this.numSpies = gameSetup.numSpies;
    this.role = gameSetup.role;
    this.playerName = this.$route.params.playerName;
    this.missionLeader = gameSetup.missionLeader;
    this.players = gameSetup.players;
    this.eligibleToBeMissionLeaders = gameSetup.eligibleToBeMissionLeaders;
  },
  mounted() {
    this.sockets.subscribe(SocketConstants.missionLeaderSelections, (data) => {
      this.onMission = data.onMission;
      this.nextMissionLeader = data.nextMissionLeader;
    });
    this.sockets.subscribe(SocketConstants.missionResults, (data) => {
      this.missionResults = data;
      this.selectedColor = null;
      this.selectedColorElement = null;
      this.cardSubmitted = false;
      this.onMission = false;
      this.missionLeader = null;
      this.displayMissionResults();
    });
    this.sockets.subscribe(SocketConstants.nextRound, () => {
      this.round++;
      this.updateMissionResults();
      this.loadNextRound();
      if (this.missionPasses === 3 || this.missionFailures === 3) {
        this.gameOver = true;
        this.$socket.emit(SocketConstants.endGame);
      }
    });
    this.sockets.subscribe(SocketConstants.endGame, (data) => {
      this.gameOver = true;
      this.spies = data;
    });
    this.sockets.subscribe(SocketConstants.vetoMission, (data) => {
      // assigning to nextMissionLeader so that loadNextRound assigns
      // nextMissionLeader to missionLeader and sets nextMissionLeader to null
      this.nextMissionLeader = data.nextMissionLeader;
      this.loadNextRound();
    });
    this.sockets.subscribe(SocketConstants.reconnectToGame, (data) => {
      // Reset certain values so that proper items are displayed
      // For example, clear out mission results if we are on different screen
      this.playersGoingOnMission = [];
      this.onMission = false;
      this.missionResults = [];

      // Game setup details
      this.someData = data;
      let gameStats = data.gameStats;
      let playerDetails = data.playerDetails;
      let gameStageData = data.gameStage;

      this.numSpies = gameStats.numSpies;
      this.playersOnMissionPerRound = gameStats.playersOnMissionPerRound;
      this.failuresNeededPerRound = gameStats.failuresNeededPerRound;
      this.eligibleToBeMissionLeaders = gameStats.eligibleToBeMissionLeaders;
      this.missionLeader = gameStats.missionLeader;
      this.players = gameStats.players;
      this.spies = gameStats.spies;
      this.missionPasses = gameStats.missionPasses;
      this.missionFailures = gameStats.missionFailures;

      // Player details
      this.role = playerDetails.role;
      this.playerName = playerDetails.playerDetails;

      // Game stage for player details
      if (gameStageData.nextMissionLeader)
        this.nextMissionLeader = gameStageData.nextMissionLeader;
      if (gameStageData.onMission) this.onMission = gameStageData.onMission;
      if (gameStageData.spies) this.spies = gameStageData.spies;
      if (gameStageData.missionResults)
        this.missionResults = gameStageData.missionResults;
    });
  },
  beforeDestroy() {
    this.sockets.unsubscribe(SocketConstants.missionLeaderSelections);
    this.sockets.unsubscribe(SocketConstants.missionResults);
    this.sockets.unsubscribe(SocketConstants.nextRound);
    this.sockets.unsubscribe(SocketConstants.endGame);
    this.sockets.unsubscribe(SocketConstants.vetoMission);
    this.sockets.unsubscribe(SocketConstants.reconnectToGame);
  },
  methods: {
    toggleDisplayRole() {
      this.showRole = !this.showRole;
    },
    selectPlayer(e, category) {
      if (category === "missionMember") {
        if (e.target.classList.contains("selected")) {
          e.target.classList.remove("selected");
          let indexOfElement = this.playersGoingOnMission.indexOf(
            e.target.getAttribute("data-player")
          );
          this.playersGoingOnMission.splice(indexOfElement, 1);
          this.playersGoingOnMissionElements.splice(indexOfElement, 1);
        } else {
          this.playersGoingOnMission.push(e.target.getAttribute("data-player"));
          this.playersGoingOnMissionElements.push(e.target);
          e.target.classList.add("selected");
        }
      } else if (category === "missionLeader") {
        if (this.nextMissionLeaderElement) {
          this.nextMissionLeaderElement.classList.remove("selected");
        }
        this.nextMissionLeaderElement = e.target;
        this.nextMissionLeader = e.target.getAttribute("data-player");
        e.target.classList.add("selected");
      }
    },
    selectMissionCard(e) {
      if (
        !(
          e.target.getAttribute("data-color") === "red" &&
          this.role.toLowerCase() !== "spy"
        )
      ) {
        if (this.selectedColorElement) {
          this.selectedColorElement.classList.remove("selected");
        }
        this.selectedColor = e.target.getAttribute("data-color");
        this.selectedColorElement = e.target;
        this.selectedColorElement.classList.add("selected");
      }
    },
    submitMissionLeaderSelections() {
      this.$socket.emit(SocketConstants.missionLeaderSelections, {
        nextMissionLeader: this.nextMissionLeader,
        playersGoingOnMission: this.playersGoingOnMission,
      });
      (this.playersGoingOnMission = []),
        (this.playersGoingOnMissionElements = []),
        (this.submittedMissionLeaderSelections = true);
    },
    submitMissionMemberCard() {
      this.$socket.emit(SocketConstants.missionResults, {
        color: this.selectedColor,
        numPlayersOnMission: this.playersOnMissionPerRound[this.round],
      });
      this.cardSubmitted = true;
    },
    displayMissionResults() {
      var missionResults = document.getElementsByClassName("mission-result");
      for (let i = 0; i < missionResults.length; i++) {
        missionResults[i].style.display = "block";
      }
    },
    emitNextRound() {
      this.$socket.emit(SocketConstants.nextRound);
    },
    loadNextRound() {
      this.missionLeader = this.nextMissionLeader;
      this.nextMissionLeader = null;
      this.nextMissionLeaderElement = null;
      this.submittedMissionLeaderSelections = false;
      this.missionResults = [];
    },
    updateMissionResults() {
      let reds = 0;
      for (let i = 0; i < this.missionResults.length; i++) {
        if (this.missionResults[i].toLowerCase() === "red") {
          reds++;
        }
      }
      if (this.failuresNeededPerRound[this.round] <= reds) {
        this.missionFailures++;
      } else {
        this.missionPasses++;
      }
    },
    endGame() {
      let endGame = confirm("Are you sure you would like to end the game?");
      if (endGame) {
        this.$socket.emit(SocketConstants.endGame);
      }
    },
    handleVeto() {
      this.$socket.emit(SocketConstants.vetoMission, {
        nextMissionLeader: this.nextMissionLeader,
      });
    },
  },
};
</script>

<style scoped>
.spy {
  color: red;
}
.resistance {
  color: green;
}
.merlin {
  color: orange;
}
#role {
  font-weight: bold;
}
#spies-list-container {
  padding: 20px;
}
#spies-list-container > p {
  font-weight: bold;
  margin-top: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

li.selectable {
  padding: 20px;
  margin: 20px auto;
}

li.selectable:nth-child(even) {
  background-color: lightblue;
}

li.selectable:nth-child(odd) {
  background-color: pink;
}

li.selectable:hover,
.selected {
  box-shadow: 0px 0px 50px black;
  z-index: 3;
}
li.selectable:hover,
li.selected {
  font-weight: bold;
  transform: translateX(-10px);
}
.card {
  height: 100%;
  border-radius: 20px;
  max-width: 20vw;
  flex: 1 1 auto;
}
.black {
  background-color: black;
}
.red {
  background-color: red;
}

.mission-result {
  opacity: 0;
  animation: fadeIn 5s forwards;
  animation-name: fadeIn;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}
.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 250px;
}
#stats,
#role-container-wrapper {
  position: relative;
  border: 1px solid black;
  padding: 10px;
}
#role-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#role-container-wrapper > button {
  display: block;
  /* position: absolute; */
  bottom: 5px;
}
#game-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 250px;
}
div {
  margin: 10px 0;
}
#end-game-button {
  margin-top: 100px;
}
#submit-mission-leader-selection-button {
  margin-top: 25px;
}
#choose-next-mission-leader-label {
  margin-top: 50px;
}
#winner-container {
  font-size: 40px;
}
#spy-reveal-container {
  font-size: 20px;
  animation-name: fadeIn;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
#next-round-button,
#submit-mission-members-selection-button {
  margin-top: 20px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>