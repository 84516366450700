<template>
  <div id="taboo">
    <h1>Taboo</h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
};
</script>

<style>
</style>