var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"play"}},[_c('div',{attrs:{"id":"game-info-container"}},[_c('div',{attrs:{"id":"stats"}},[_c('p',[_c('span',[_vm._v("Round: ")]),_c('span',[_vm._v(_vm._s(_vm.round + 1))])]),_c('p',[_c('span',[_vm._v("Mission Passes: ")]),_c('span',[_vm._v(_vm._s(_vm.missionPasses))])]),_c('p',[_c('span',[_vm._v("Mission Failures: ")]),_c('span',[_vm._v(_vm._s(_vm.missionFailures))])])]),_c('div',{attrs:{"id":"roundInformation"}},[_c('p',[_vm._v(" Number of failures required to fail mission: "+_vm._s(_vm.failuresNeededPerRound[_vm.round])+" ")]),_c('p',[_vm._v(" Number of members going on mission: "+_vm._s(_vm.playersOnMissionPerRound[_vm.round])+" ")])]),_c('div',{attrs:{"id":"role-container-wrapper"}},[(_vm.role && _vm.showRole)?_c('div',{attrs:{"id":"role-container"}},[_c('span',[_vm._v("You are ")]),_c('br'),_c('span',{class:_vm.role.toLowerCase(),attrs:{"id":"role"}},[_vm._v(_vm._s(_vm.role))]),(
            _vm.role.toLowerCase() === 'spy' || _vm.role.toLowerCase() === 'merlin'
          )?_c('div',{attrs:{"id":"spies-list-container"}},[_c('p',[_vm._v("Spies:")]),_c('ul',{attrs:{"id":"spies-list"}},_vm._l((_vm.spies),function(spy){return _c('li',{key:spy},[_vm._v(_vm._s(spy))])}),0)]):_vm._e()]):_vm._e(),_c('button',{on:{"click":_vm.toggleDisplayRole}},[_vm._v("Show/Hide Role")])])]),(!_vm.gameOver)?_c('div',{attrs:{"id":"game-container"}},[(_vm.missionLeader && _vm.missionLeader !== _vm.playerName)?_c('div',[_c('span',[_vm._v("Mission Leader is: ")]),_vm._v(" "),_c('b',[_vm._v(_vm._s(_vm.missionLeader))])]):_vm._e(),(_vm.missionLeader && _vm.missionLeader === _vm.playerName)?_c('div',[_c('b',[_vm._v("You are the mission leader")])]):_vm._e(),(_vm.missionLeader === _vm.playerName && !_vm.submittedMissionLeaderSelections)?_c('div',{attrs:{"id":"mission-leader-options"}},[_c('p',[_vm._v(" Who would you like to go on this mission? Choose ("+_vm._s(_vm.playersOnMissionPerRound[_vm.round])+") ")]),_c('b',[_vm._v("***NOTE: If mission has been vetoed (no screen to veto, veto portion will happen verbally, select next mission leader and \"vetoed\". \"Vetoed\" button can only be selected if 0 team members are selected***")]),_c('ul',{attrs:{"id":"players-for-mission"}},_vm._l((_vm.players),function(player){return _c('li',{key:(player + "Player"),staticClass:"selectable",attrs:{"data-player":player},on:{"click":function (e) { return _vm.selectPlayer(e, 'missionMember'); }}},[_vm._v(" "+_vm._s(player)+" ")])}),0),_c('p',{attrs:{"id":"choose-next-mission-leader-label"}},[_vm._v("Choose Next Mission Leader:")]),_c('ul',{attrs:{"id":"players-for-ml"}},_vm._l((_vm.eligibleToBeMissionLeaders),function(player){return _c('li',{key:(player + "ML"),staticClass:"selectable",attrs:{"data-player":player},on:{"click":function (e) { return _vm.selectPlayer(e, 'missionLeader'); }}},[_vm._v(" "+_vm._s(player)+" ")])}),0),(
          _vm.missionLeader === _vm.playerName && !_vm.submittedMissionLeaderSelections
        )?_c('div',{attrs:{"id":"mission-leader-buttons-container"}},[_c('button',{attrs:{"id":"submit-mission-leader-selection-button","disabled":_vm.playersGoingOnMission.length !==
              _vm.playersOnMissionPerRound[_vm.round] || !_vm.nextMissionLeader},on:{"click":_vm.submitMissionLeaderSelections}},[_vm._v(" Submit Selections ")]),_c('br'),_c('br'),_c('br'),_c('button',{attrs:{"id":"veto-mission-leader-selection-button","disabled":_vm.playersGoingOnMission.length > 0 || !_vm.nextMissionLeader},on:{"click":_vm.handleVeto}},[_vm._v(" Vetoed ")])]):_vm._e()]):_vm._e(),(_vm.missionResults.length > 0)?_c('div',{attrs:{"id":"mission-results-container"}},[_c('div',{staticClass:"card-container"},_vm._l((_vm.missionResults),function(card,i){return _c('div',{key:'missionResult' + i,class:['mission-result', 'card', card],style:({ animationDelay: 1.5 * i + 's' })})}),0),(
          _vm.missionResults.length > 0 &&
          !(_vm.missionPasses == 3 || _vm.missionFailures == 3)
        )?_c('button',{attrs:{"id":"next-round-button"},on:{"click":_vm.emitNextRound}},[_vm._v(" Next Round >>> ")]):_vm._e()]):_vm._e(),(_vm.onMission && !_vm.cardSubmitted)?_c('div',{attrs:{"id":"select-card-container"}},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card black",attrs:{"data-color":"black"},on:{"click":_vm.selectMissionCard}}),_c('div',{staticClass:"card red",attrs:{"data-color":"red"},on:{"click":_vm.selectMissionCard}})]),(_vm.onMission)?_c('button',{attrs:{"id":"submit-mission-members-selection-button","disabled":!_vm.selectedColor},on:{"click":_vm.submitMissionMemberCard}},[_vm._v(" Submit Card ")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.gameOver)?_c('div',{attrs:{"id":"game-over-container"}},[(_vm.missionFailures === 3 || _vm.missionPasses === 3)?_c('div',{attrs:{"id":"winner-container"}},[(_vm.missionFailures === 3)?_c('span',{staticClass:"winning-group",attrs:{"id":"spies-won-text"}},[_vm._v("SPIES")]):_vm._e(),(_vm.missionPasses === 3)?_c('span',{staticClass:"winning-group",attrs:{"id":"resistance-won-text"}},[_vm._v("RESISTANCE")]):_vm._e(),_c('span',[_vm._v(" WON!!!")])]):_vm._e(),_c('div',[_vm._v("Spies:")]),_c('ul',{attrs:{"id":"spy-reveal-container"}},_vm._l((_vm.spies),function(spy){return _c('li',{key:spy,staticClass:"spy-reveal"},[_vm._v(_vm._s(spy))])}),0)]):_vm._e(),_c('button',{attrs:{"id":"end-game-button"},on:{"click":_vm.endGame}},[_vm._v("End Game")]),(_vm.someData)?_c('p',[_vm._v(" Device has reconnected"),_c('br'),_vm._v(_vm._s(JSON.stringify(_vm.someData))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }