<template>
  <div id="home">
    <p>Home</p>
    Round Duration: <input type="number" v-model="gameDuration" />s
    <br />
    <br />
    Number of Skips: <input type="number" v-model="skipsAllowed" />
    <br />
    <br />
    <input
      type="checkbox"
      v-if="speechRecognitionSupportedByDevice"
      v-model="enableSpeechRecognition"
    />Enable speech recognition
    <br />
    <br />
    <button @click="startGame">Start game</button>
    <speech-to-text-modal
      :closeSpeechToTextModal="closeSpeechToTextModal"
      @close="closeSpeechToTextModal($event)"
      v-if="audioStream"
      :speechToTextResults="speechToTextResults"
      :speechToTextResultsConfidence="speechToTextResultsConfidence"
    />
  </div>
</template>

<script>
import SpeechToTextService from "../../services/taboo/SpeechToTextService";
import SpeechToTextModal from "./SpeechToTextModal.vue";

export default {
  components: { SpeechToTextModal },
  data() {
    return {
      gameDuration: 90,
      skipsAllowed: 1,
      enableSpeechRecognition: false,
      audioStream: null,
      speechToTextResults: "",
      speechToTextResultsConfidence: 0,
      speechRecognitionSupportedByDevice:
        SpeechToTextService.speechRecognitionSupportedByDevice,
    };
  },
  methods: {
    startGame() {
      this.$router.push({
        name: "Taboo_Play",
        params: {
          gameDuration: this.gameDuration,
          skipsAllowed: this.skipsAllowed,
          enableSpeechRecognition: this.enableSpeechRecognition,
        },
      });
    },
    requestMicAccess() {
      this.audioStream = true;
      SpeechToTextService.startAudioRecording(this.getSpeechToTextResults);
    },
    closeSpeechToTextModal() {
      this.audioStream = null;
      SpeechToTextService.stopAudioRecording();
      this.speechToTextResults = "";
    },
    getSpeechToTextResults(results, confidence) {
      this.speechToTextResults = results;
      this.speechToTextResultsConfidence = confidence;
    },
  },
  watch: {
    enableSpeechRecognition: function (newVal) {
      if (newVal) {
        this.requestMicAccess();
      } else {
        SpeechToTextService.stopAudioRecording(this.audioStream);
      }
    },
  },
};
</script>

<style>
</style>
